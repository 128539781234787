/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.spin {
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-out {
  opacity: 0;
  transform: translateY(20px);
}

.slide-in-right {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.slide-out-right {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.slide-in-left {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.slide-out-left {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}
