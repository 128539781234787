@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "safe";
  src: url("/public/fonts/SafetyMedium.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "cap";
  src: url("/public/fonts/wheaton\ capitals.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  background: #111010;
  font-family: "safe";
}
.font-bold {
  font-family: "cap";
}
html {
  scroll-behavior: smooth;
}
